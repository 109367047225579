import { FunctionComponent, MouseEvent, default as React } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Icon } from 'rs-emd-ui-atoms';
import { useUser, useUserDispatch } from '../../../../../components/app/user-context';
import { loginService } from '../../../../../services/login-service';
import { getLabel } from '../../../helpers/html.utils';
import { ILabel } from '../../../models/common/label.model';
import styles from './account-menu-component.module.scss';

type AccountMenuProps = {
  labels?: ILabel[];
  className: string;
  onCloseMenuClick: (e?: MouseEvent<HTMLDivElement> | MouseEvent<HTMLAnchorElement>) => void;
};

export const AccountMenu: FunctionComponent<AccountMenuProps> = (props: AccountMenuProps) => {
  const user = useUser();
  const userDispatch = useUserDispatch();
  const location = useLocation();
  const history = useHistory();

  return (
    <>
      <div className={`${styles['account-menu-container']} ${styles[props.className]}`}>
        {!user.customer.isLoggedIn && (
          <div className={styles['cta-container']}>
            <Button
              text={getLabel(props.labels, 'log_in')}
              href={`/user/login?redirectTo=${location.pathname}`}
              onClick={props.onCloseMenuClick}
            />
            <Button buttonType='outline' href={`/user/register?redirectTo=${location.pathname}`} onClick={props.onCloseMenuClick}>
              {getLabel(props.labels, 'sign_up')}
            </Button>
          </div>
        )}
        <div className={styles['menu-container']}>
          <Link to={`/user/myaccount`} onClick={props.onCloseMenuClick}>
            <Icon name='home'></Icon>
            <span>{getLabel(props.labels, 'overview')}</span>
            <Icon name='chev-right' className={styles.chev}></Icon>
          </Link>
          <Link to={`/user/myaccount/orders`} onClick={props.onCloseMenuClick}>
            <Icon name='parcel'></Icon>
            <span>{getLabel(props.labels, 'orders')}</span>
            <Icon name='chev-right' className={styles.chev}></Icon>
          </Link>
          <Link to={`/user/myaccount/partslists`} onClick={props.onCloseMenuClick}>
            <Icon name='star-outline'></Icon>
            <span>{getLabel(props.labels, 'parts_list')}</span>
            <Icon name='chev-right' className={styles.chev}></Icon>
          </Link>
          <Link to={`/user/profile`} onClick={props.onCloseMenuClick}>
            <Icon name='account'></Icon>
            <span>{getLabel(props.labels, 'profile')}</span>
            <Icon name='chev-right' className={styles.chev}></Icon>
          </Link>
          <Link to={`/user/forgotPassword`} onClick={props.onCloseMenuClick}>
            <Icon name='unlocked'></Icon>
            <span>{getLabel(props.labels, 'reset_password')}</span>
            <Icon name='chev-right' className={styles.chev}></Icon>
          </Link>
        </div>
        {user.customer.isLoggedIn && (
          <div className={`${styles['cta-container']}`}>
            <Button buttonType='outline' href={`/user/register?redirectTo=${location.pathname}`} onClick={logout}>
              {getLabel(props.labels, 'logout')}
            </Button>
          </div>
        )}
      </div>
    </>
  );

  function logout() {
    props.onCloseMenuClick();
    loginService.logout(userDispatch).then(() => history.push('/user/login?logout=1'));
  }
};
